import {AXIOS} from "../../http-commons";
import TokenService from "./token.service";

// const API_URL = 'http://localhost:8088/api';
const setup = (store) => {
  AXIOS.interceptors.request.use(
    (config) => {
      const token = TokenService.getLocalAccessToken();
      if (token) {
        config.headers["Authorization"] = 'Bearer ' + token;
      }
      return config;
    },
    (error) => {
      console.log("erro: ", error)
      return Promise.reject(error);
    }
  );
  AXIOS.interceptors.response.use(
    (res) => {
      return res;
    },
    async (err) => {
      console.log("err: ", err)
      const originalConfig = err.config;
      console.log("originalConfig: ", originalConfig)

      if (originalConfig.url !== "/api/auth/signin" && err.response) {
        // if (originalConfig.url !== API_URL + "/auth/signin" && err.response) {
        // Access Token was expired

        if (err.response.status === 401 && !originalConfig._retry) {
          originalConfig._retry = true;
          try {
            console.log("starting refresh...")
            const rs = await AXIOS.post("/api/auth/refreshtoken", {
              // const rs = await AXIOS.post(API_URL + "/auth/refreshtoken", {
              refreshToken: TokenService.getLocalRefreshToken(),
            });
            const {accessToken} = rs.data;
            store.dispatch('auth/refreshToken', accessToken);
            TokenService.updateLocalAccessToken(accessToken);
            return AXIOS(originalConfig);
          } catch (_error) {
            return Promise.reject(_error);
          }
        }
      }
      return Promise.reject(err);
    }
  );
};
export default setup;
