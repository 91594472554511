import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './plugins/base'
import './plugins/vee-validate'
import vuetify from './plugins/vuetify'
import i18n from './i18n'
import DatetimePicker from 'vuetify-datetime-picker'
import globalMixin from './plugins/mixins';
import {createPinia, PiniaVuePlugin} from 'pinia'
import setupInterceptors from './services/setupInterceptors';

Vue.use(DatetimePicker)

Vue.mixin(globalMixin);

Vue.config.productionTip = false

Vue.use(PiniaVuePlugin)
const pinia = createPinia()

setupInterceptors(store);

new Vue({
  router,
  store,
  vuetify,
  i18n,
  pinia,
  render: h => h(App),
}).$mount('#app')
