import Vue from 'vue'
import Vuetify from 'vuetify/lib'
import i18n from '@/i18n'
import '@/sass/overrides.sass'

Vue.use(Vuetify)

const primaryTheme = {
  primary: '#4CAF50',
  secondary: '#8BC34A',
  accent: '#1B5E20',
  info: '#00CAE3',
}

const darkTheme = {
  primary: '#4CAF50',
  secondary: '#9C27b0',
  accent: '#1B5E20',
  info: '#00CAE3',
}

export default new Vuetify({
  lang: {
    t: (key, ...params) => i18n.t(key, params),
  },
  theme: {
    themes: {
      dark: darkTheme,
      light: primaryTheme,
    },
  },
})
