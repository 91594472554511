import {AXIOS} from "../../http-commons";
import 'element-ui/lib/theme-chalk/index.css';
import {Message} from "element-ui"

export default {
  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    }
  },
  methods: {
    // getUserById() {
    //   let params = new URLSearchParams();
    //   params.append('userId', this.currentUser.id);
    //   AXIOS.get('/api/user', {params})
    //     .then(response => {
    //       this.user = response.data;
    //     })
    //     .catch(e => {
    //       this.errors.push(e)
    //     })
    // },
    // getAllOrders() {
    //   console.log("ord")
    //   let params = new URLSearchParams();
    //   params.append('userId', this.currentUser.id);
    //   AXIOS.get('/api/getAllOrders', {params})
    //     .then(response => {
    //       this.orders = response.data;
    //     })
    // },
    // getAgencyList() {
    //   console.log("agent")
    //   let params = new URLSearchParams();
    //   params.append('userId', this.currentUser.id);
    //   AXIOS.get('/api/getAllAgencies', {params})
    //     .then(response => {
    //       this.agencies = response.data;
    //     })
    // },
    getStatusList() {
      AXIOS.get('/api/getAllStatuses')
        .then(response => {
          this.statuses = response.data;
        })
    },
    // getManagerList() {
    //   let params = new URLSearchParams();
    //   params.append('userId', this.currentUser.id);
    //   AXIOS.get('/api/getAllManagers', {params})
    //     .then(response => {
    //       this.managers = response.data;
    //     })
    // },
    // getPayerList() {
    //   let params = new URLSearchParams();
    //   params.append('userId', this.currentUser.id);
    //   AXIOS.get('/api/getAllPayers', {params})
    //     .then(response => {
    //       this.payers = response.data;
    //     })
    // },
    getCatToolList() {
      let params = new URLSearchParams();
      params.append('userId', this.currentUser.id);
      AXIOS.get('/api/getAllCatTools', {params})
        .then(response => {
          this.catTools = response.data;
        })
    },
    getTopicList() {
      let params = new URLSearchParams();
      params.append('userId', this.currentUser.id);
      AXIOS.get('/api/getAllTopics', {params})
        .then(response => {
          this.topics = response.data;
        })
    },
    getLanguageList() {
      let params = new URLSearchParams();
      params.append('userId', this.currentUser.id);
      AXIOS.get('/api/getLangPairs', {params})
        .then(response => {
          this.langPairs = response.data;
        })
    },
    getOrderTypes() {
      let params = new URLSearchParams();
      params.append('userId', this.currentUser.id);
      AXIOS.get('/api/getAllOrderTypes', {params})
        .then(response => {
          this.orderTypes = response.data;
        })
    },
    getAllTemplates() {
      AXIOS.get('/api/getAllEmailTemplates/')
        .then(response => {
          this.templates = response.data;
        })
    },
    getAllCalculationTypes() {
      let params = new URLSearchParams();
      params.append('userId', this.currentUser.id);
      AXIOS.get('/api/getAllCalculationTypes', {params})
        .then(response => {
          this.calculationTypes = response.data;
        })
    },
    getActiveTopics() {
      let params = new URLSearchParams();
      params.append('userId', this.currentUser.id);
      AXIOS.get('/api/getActiveTopics', {params})
        .then(response => {
          this.topics = response.data;
        })
    },
    getActiveLanguages() {
      let params = new URLSearchParams();
      params.append('userId', this.currentUser.id);
      AXIOS.get('/api/getActiveLangPairs', {params})
        .then(response => {
          this.langPairs = response.data;
        })
    },
    getActiveOrderTypes() {
      let params = new URLSearchParams();
      params.append('userId', this.currentUser.id);
      AXIOS.get('/api/getActiveOrderTypes', {params})
        .then(response => {
          this.orderTypes = response.data;
        })
    },
    getActiveCatTools() {
      let params = new URLSearchParams();
      params.append('userId', this.currentUser.id);
      AXIOS.get('/api/getActiveCatTools', {params})
        .then(response => {
          this.catTools = response.data;
        })
    },
    getActiveCalculationTypes() {
      let params = new URLSearchParams();
      params.append('userId', this.currentUser.id);
      AXIOS.get('/api/getActiveCalculationTypes', {params})
        .then(response => {
          this.calculationTypes = response.data;
        });
    },
    userNotification(response, action, objectType) {
      let mes;
      if (action === 'create') {
        mes = objectType + ' ' + response + ' was <strong>created</strong> successfully'
      }
      if (action === 'delete') {
        mes = objectType + ' ' + response + ' was <strong>deleted</strong>'
      }
      if (action === 'update') {
        mes = objectType + ' was <strong>updated</strong> successfully'
      }
      if (action === 'clone') {
        mes = objectType + ' was <strong>cloned</strong> successfully'
      }
      if (action === 'activate') {
        mes = objectType + ' is <strong>activated</strong>'
      }
      if (action === 'deactivate') {
        mes = objectType + ' is <strong>deactivated</strong>'
      }
      if (action === 'upload') {
        mes = objectType + ' <strong>uploaded to server</strong>'
      }
      if (action === 'avatar') {
        mes = objectType + ' is <strong>changed</strong>'
      }
      if (action === 'replace') {
        mes = objectType + ' was <strong>replaced successfully!</strong>'
      }
      if (action === 'discharge') {
        mes = objectType + ' was <strong>discharged successfully!</strong>'
      }
      if (action === 'codeChange') {
        mes = objectType + ' was changed to <strong>' + response + '</strong>'
      }
      Message({
        message: mes,
        type: 'success',
        dangerouslyUseHTMLString: true,
        duration: 3000
      });
    },
  }
}
