import Vue from 'vue'
import VueI18n from 'vue-i18n'

import en from 'vuetify/lib/locale/en'
import es from 'vuetify/lib/locale/es'
import uk from 'vuetify/lib/locale/uk'
import fr from 'vuetify/lib/locale/fr'
import de from 'vuetify/lib/locale/de'
import cn from 'vuetify/lib/locale/zh-Hans'

Vue.use(VueI18n)

const messages = {
  uk: {
    ...require('@/locales/uk.json'),
    $vuetify: uk,
  },
  en: {
    ...require('@/locales/en.json'),
    $vuetify: en,
  },
  fr: {
    ...require('@/locales/fr.json'),
    $vuetify: fr,
  },
  de: {
    ...require('@/locales/de.json'),
    $vuetify: de,
  },
  cn: {
    ...require('@/locales/cn.json'),
    $vuetify: cn,
  },
  es: {
    ...require('@/locales/es.json'),
    $vuetify: es,
  },
}

export default new VueI18n({
  // locale: process.env.VUE_APP_I18N_LOCALE,
  locale: 'en',
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'en',
  messages,
})
