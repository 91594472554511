import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

export default new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return {x: 0, y: 0}
  },
  routes: [
    {
      path: '/404',
      name: '404',
      component: () => import('@/views/auth/404'),
    }, {
      path: '*',
      redirect: '/404'
    },

    {
      path: '/',
      component: () => import('@/views/dashboard/Index'),
      children: [
        {
          name: 'Dashboard',
          path: '',
          component: () => import('@/views/dashboard/Dashboard'),
        },
        {
          name: 'References',
          path: '/references',
          component: () => import('@/views/references/References'),
        },
        {
          name: 'Tasks',
          path: '/tasks',
          component: () => import('@/views/tasks/Tasks'),
        },
        {
          name: 'Invoicing',
          path: '/invoicing/:agencyId?',
          component: () => import('@/views/finance/Invoicing'),
        },
        {
          name: 'Agencies',
          path: '/agencymenu',
          component: () => import('@/views/agencies/Agencies'),
        },
        {
          name: 'Finance',
          path: '/finance',
          component: () => import('@/views/finance/Finance'),
        },
        {
          name: 'Agency Card',
          path: '/agency/:agencyId',
          component: () => import('@/views/agencies/AgencyCard'),
        },
        {
          name: 'All Agencies',
          path: '/allagencies',
          component: () => import('@/views/agencies/AllAgencies'),
        },
        {
          name: 'Order menu',
          path: '/ordermenu',
          component: () => import('@/views/orders/Orders'),
        },
        {
          name: 'All Managers',
          path: '/managers',
          component: () => import('@/views/managers/Managers'),
        },
        {
          name: 'All Payers',
          path: '/payers',
          component: () => import('@/views/payers/Payers'),
        },
        {
          name: 'Payment history',
          path: '/paymenthistory',
          component: () => import('@/views/finance/PaymentHistory'),
        },
        {
          name: 'Payment details',
          path: '/paymentdetails',
          component: () => import('@/views/finance/PaymentDetails'),
        },
        {
          name: 'Files Page',
          path: '/files',
          component: () => import('@/views/files/FilesPage'),
        },
        {
          name: 'Orders',
          path: '/orders',
          component: () => import('@/views/orders/OrdersPage'),
        },
        {
          name: 'Create Order',
          path: '/createorder',
          component: () => import('@/views/orders/CreateOrder'),
        },
        {
          name: 'Create Agency',
          path: '/createagency',
          component: () => import('@/views/agencies/CreateAgency'),
        },
        {
          name: 'Create Payer',
          path: '/createpayer',
          component: () => import('@/views/payers/CreatePayer'),
        },
        {
          name: 'Create Task',
          path: '/createtask',
          component: () => import('@/views/tasks/CreateTask'),
        },
        {
          name: 'Create Manager',
          path: '/createmanager',
          component: () => import('@/views/managers/CreateManager'),
        },
        {
          name: 'Order Card',
          path: '/order/:orderId',
          component: () => import('@/views/orders/OrderCard'),
        },
        {
          name: 'History Card',
          path: '/history/:orderId',
          component: () => import('@/views/orders/HistoryCard'),
        },
        {
          name: 'Manager Card',
          path: '/manager/:managerId',
          component: () => import('@/views/managers/ManagerCard'),
        },
        {
          name: 'Payer Card',
          path: '/payer/:payerId',
          component: () => import('@/views/payers/PayerCard'),
        },
        {
          name: 'Task Card',
          path: '/task/:taskId',
          component: () => import('@/views/tasks/TaskCard'),
        },
        {
          name: 'File Card',
          path: '/file/:fileId',
          component: () => import('@/views/files/FileCard'),
        },
        {
          name: 'Calendar',
          path: '/calendar',
          component: () => import('@/views/calendar/Calendar'),
        },
        {
          name: 'Recycle Bin',
          path: '/recyclebin',
          component: () => import('@/views/recyclebin/RecycleBin'),
        },
        {
          name: 'User profile',
          path: 'pages/user',
          component: () => import('@/views/dashboard/pages/UserProfile'),
        },
        {
          name: 'Notifications',
          path: 'components/notifications',
          component: () => import('@/views/dashboard/component/Notifications'),
        },
        {
          name: 'Settings',
          path: '/settings',
          component: () => import('@/views/settings/Settings'),
        },
        {
          path: '/auth',
          component: () => import('@/views/auth/Auth'),
        },
      ],
    },
  ],
})
