<template>
  <router-view/>
</template>

<script>
  import EventBus from "./common/EventBus";

  export default {
    name: 'App',
    methods: {
      logOut() {
        this.$store.dispatch('auth/logout');
      }
    },
    mounted() {
      EventBus.on("logout", () => {
        this.logOut();
      });
    },
    beforeDestroy() {
      EventBus.remove("logout");
    }
  }
</script>
