import {AXIOS} from "../../http-commons";
import TokenService from "./token.service";

// const API_URL = 'http://localhost:8088/api';

class AuthService {
  login({username, password}) {
    return AXIOS
      // .post(API_URL + '/auth/signin', {
      .post("/api/auth/signin", {
        username,
        password
      })
      .then((response) => {
        console.log("res:", response.data)
        if (response.data.accessToken) {
          TokenService.setUser(response.data);
          console.log("res after: " + response.data.accessToken)
        }
        return response.data;
      });
  }

  logout() {
    // let userId = JSON.parse(localStorage.getItem("user")).id;
    // AXIOS.post(API_URL + "/auth/logout", {userId});
    return TokenService.removeUser();
  }

  register({username, email, password}) {
    // return AXIOS.post(API_URL + "/auth/signup", {
    return AXIOS.post("/api/auth/signup", {
      username,
      email,
      password
    });
  }
}

export default new AuthService();
